import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostList from "../components/Blog/BlogPostList";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";
import SubscribeForm from "../components/Form/Subscribe";

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    blogs: allSanityTrueBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const postNodes =
    data &&
    data.blogs &&
    mapEdgesToNodes(data.blogs)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <Layout>
      <SearchEngineOptimization
        title="News & Information | Brierton, Jones & Jones"
        description="Keep up with all the news and information regarding Brierton, Jones & Jones, LLP and estate planning in San Diego here."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-32">
        <div className="absolute left-0 top-0 h-full w-full">
          <StaticImage
            src="../images/News/Hero Desktop.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container relative">
          <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-50">
            From Our Firm
          </div>
          <h1 className="mb-0 text-white">Blogs</h1>
        </div>
      </section>

      <section className="pb-20 pt-16 md:pb-32 md:pt-22">
        <div className="container">
          <div className="grid gap-y-6 md:grid-cols-12 md:gap-x-10 lg:gap-x-16">
            <div className="md:col-span-8 md:col-start-1">
              {postNodes && postNodes.length > 0 && (
                <BlogPostList nodes={postNodes} />
              )}
            </div>
            <div className="md:col-span-4 md:col-end-13">
              <div className="sticky top-32 md:ml-auto md:max-w-[296px]">
                <div className="bg-white px-6 py-9 text-center shadow-4xl">
                  <StaticImage
                    src="../images/News/Logo.jpg"
                    loading="lazy"
                    width={80}
                    className="mx-auto mb-7"
                  />
                  <p className="text-sm">Connect with us on LinkedIn</p>
                  <a
                    href="https://www.linkedin.com/company/brierton-jones-&-jones/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-secondary-900 hover:text-secondary-500 no-underline"
                  >
                    <i className="fab fa-linkedin-in text-xl"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscribeForm />
      <CallToAction />
    </Layout>
  );
};

export default Page;
