import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import ButtonSolid from "../Button/ButtonSolid";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const StyledForm = styled.div`
  ${tw`shadow-4xl !bg-white max-w-[800px] mx-auto py-[80px] px-[86px] mb-[120px]`}
  [multiple],
  [type="email"],
  [type="tel"],
  [type="text"],
  select,
  textarea {
    ${tw`w-full text-typography-body bg-transparent px-4 py-2.5 border border-solid border-gray-300 rounded-sm transition-colors duration-300 ease-linear focus:border-transparent focus:outline-none focus:ring-3 focus:ring-seafoam/30`}
  }
`;

const SubscribeForm = () => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () =>
          (document.getElementById("contact-form-ajax-response").innerHTML =
            "Thank you for for submission! We will get in touch with you shortly."),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "contactFormSubmission",
        })
      )
      .catch((error) => alert(error));
  };

  return (
    <StyledForm>
      <div id="contact-form-ajax-response"></div>
      <form
        name="Subscribe"
        method="post"
        action=""
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="flex flex-col items-center bg-white"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Contact" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>
        <div className="font-heading text-[30px] font-bold leading-[36.57px] text-[#0A374F]">
          Stay Informed
        </div>
        <p className="mt-6 max-w-[480px] text-center font-body text-[18px] text-[#768490]">
          Subscribe to our blog to learn about important industry updates that
          could impact your future.
        </p>
        <div className="mx-auto flex w-fit grid-cols-1 flex-col items-center gap-6 md:grid-cols-2 md:gap-x-6 lg:flex-row">
          <label
            className="hidden font-body text-sm font-semibold text-gray-800"
            htmlFor="email"
          >
            Email Address
          </label>
          <input
            type="email"
            placeholder="Enter your email address"
            name="email"
            onChange={handleChange}
            required={true}
            className="!w-[360px]"
          />
          <ButtonSolid type="submit" text="Send" className="h-fit" />
        </div>
      </form>
    </StyledForm>
  );
};

export default SubscribeForm;
